exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-event-exhibition-js": () => import("./../../../src/pages/event-exhibition.js" /* webpackChunkName: "component---src-pages-event-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-filler-extender-js": () => import("./../../../src/pages/products/filler-extender.js" /* webpackChunkName: "component---src-pages-products-filler-extender-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-latex-js": () => import("./../../../src/pages/products/latex.js" /* webpackChunkName: "component---src-pages-products-latex-js" */),
  "component---src-pages-products-others-js": () => import("./../../../src/pages/products/others.js" /* webpackChunkName: "component---src-pages-products-others-js" */),
  "component---src-pages-products-pigment-dispersion-js": () => import("./../../../src/pages/products/pigment-dispersion.js" /* webpackChunkName: "component---src-pages-products-pigment-dispersion-js" */),
  "component---src-pages-products-pigment-powder-js": () => import("./../../../src/pages/products/pigment-powder.js" /* webpackChunkName: "component---src-pages-products-pigment-powder-js" */),
  "component---src-pages-products-polyisocyanate-js": () => import("./../../../src/pages/products/polyisocyanate.js" /* webpackChunkName: "component---src-pages-products-polyisocyanate-js" */),
  "component---src-pages-products-resin-js": () => import("./../../../src/pages/products/resin.js" /* webpackChunkName: "component---src-pages-products-resin-js" */),
  "component---src-pages-products-solvent-js": () => import("./../../../src/pages/products/solvent.js" /* webpackChunkName: "component---src-pages-products-solvent-js" */),
  "component---src-pages-products-tio-2-replacer-js": () => import("./../../../src/pages/products/tio2-replacer.js" /* webpackChunkName: "component---src-pages-products-tio-2-replacer-js" */),
  "component---src-pages-products-titanium-dioxide-tio-2-js": () => import("./../../../src/pages/products/titanium-dioxide-tio2.js" /* webpackChunkName: "component---src-pages-products-titanium-dioxide-tio-2-js" */),
  "component---src-templates-event-jsx-content-file-path-content-events-archidex-2023-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/archidex-2023/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-archidex-2023-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2019-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/asia-pacific-coatings-show-2019/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2019-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2022-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/asia-pacific-coatings-show-2022/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2022-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2023-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/asia-pacific-coatings-show-2023/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-asia-pacific-coatings-show-2023-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2018-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/chinacoat-2018/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2018-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2019-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/chinacoat-2019/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2019-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2023-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/chinacoat-2023/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-chinacoat-2023-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-coatings-expo-vietnam-2022-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/coatings-expo-vietnam-2022/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-coatings-expo-vietnam-2022-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-coatings-expo-vietnam-2023-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/coatings-expo-vietnam-2023/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-coatings-expo-vietnam-2023-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-gulf-coating-show-2023-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/gulf-coating-show-2023/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-gulf-coating-show-2023-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-irgce-by-margma-2018-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/irgce-by-margma-2018/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-irgce-by-margma-2018-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-irgce-by-margma-2022-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/irgce-by-margma-2022/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-irgce-by-margma-2022-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-middle-east-coatings-show-2022-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/middle-east-coatings-show-2022/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-middle-east-coatings-show-2022-index-mdx" */),
  "component---src-templates-event-jsx-content-file-path-content-events-pakistan-coating-show-2019-index-mdx": () => import("./../../../src/templates/event.jsx?__contentFilePath=/Users/shah/code-dryer-projects/focuspigment/content/events/pakistan-coating-show-2019/index.mdx" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-content-events-pakistan-coating-show-2019-index-mdx" */)
}

